import { default as users6uWLWz1708Meta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/admin/users.vue?macro=true";
import { default as applicationmi7DTukK9wMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/case/[id]/application.vue?macro=true";
import { default as attachmentsiT5d2H2DMLMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/case/[id]/attachments.vue?macro=true";
import { default as contactBtZ5Olf5oJMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/case/[id]/contact.vue?macro=true";
import { default as prescreenFmiD0GNeGFMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/case/[id]/prescreen.vue?macro=true";
import { default as scholarshipNw9MlxbBIQMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/case/[id]/scholarship.vue?macro=true";
import { default as summarypktyYR1a9mMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/case/[id]/summary.vue?macro=true";
import { default as caseECGdOnM8kpMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/case.vue?macro=true";
import { default as case_45assignmentsQ15lAuBl73Meta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/csp-admin/case-assignments.vue?macro=true";
import { default as income_45eligibilitymY8N016wnlMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/csp-admin/income-eligibility.vue?macro=true";
import { default as reportsTvxN00D78jMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/csp-admin/reports.vue?macro=true";
import { default as index9tk7GdQsAMMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/index.vue?macro=true";
import { default as new_45case9g1xdUVZTgMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/new-case.vue?macro=true";
import { default as providerlistL0N5POtZCSMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/providerlist.vue?macro=true";
import { default as eligibilityowLdLnZzxnMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/public/csp/eligibility.vue?macro=true";
import { default as _91id_93NGEc30iinjMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/report/[id].vue?macro=true";
import { default as searchrnhdzanpP9Meta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/search.vue?macro=true";
import { default as index8lzvcgMXKTMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/settings/index.vue?macro=true";
import { default as notifications48Dju0P9BOMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/settings/notifications.vue?macro=true";
import { default as settingsO6olDvphOKMeta } from "/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/settings.vue?macro=true";
export default [
  {
    name: "admin-users",
    path: "/admin/users",
    meta: users6uWLWz1708Meta || {},
    alias: ["/csp-admin/users"],
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "case",
    path: "/case",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/case.vue").then(m => m.default || m),
    children: [
  {
    name: "case-id-application",
    path: ":id()/application",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/case/[id]/application.vue").then(m => m.default || m)
  },
  {
    name: "case-id-attachments",
    path: ":id()/attachments",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/case/[id]/attachments.vue").then(m => m.default || m)
  },
  {
    name: "case-id-contact",
    path: ":id()/contact",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/case/[id]/contact.vue").then(m => m.default || m)
  },
  {
    name: "case-id-prescreen",
    path: ":id()/prescreen",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/case/[id]/prescreen.vue").then(m => m.default || m)
  },
  {
    name: "case-id-scholarship",
    path: ":id()/scholarship",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/case/[id]/scholarship.vue").then(m => m.default || m)
  },
  {
    name: "case-id-summary",
    path: ":id()/summary",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/case/[id]/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "csp-admin-case-assignments",
    path: "/csp-admin/case-assignments",
    meta: case_45assignmentsQ15lAuBl73Meta || {},
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/csp-admin/case-assignments.vue").then(m => m.default || m)
  },
  {
    name: "csp-admin-income-eligibility",
    path: "/csp-admin/income-eligibility",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/csp-admin/income-eligibility.vue").then(m => m.default || m)
  },
  {
    name: "csp-admin-reports",
    path: "/csp-admin/reports",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/csp-admin/reports.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "new-case",
    path: "/new-case",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/new-case.vue").then(m => m.default || m)
  },
  {
    name: "providerlist",
    path: "/providerlist",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/providerlist.vue").then(m => m.default || m)
  },
  {
    name: "public-csp-eligibility",
    path: "/apply/childcare/eligibility",
    meta: eligibilityowLdLnZzxnMeta || {},
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/public/csp/eligibility.vue").then(m => m.default || m)
  },
  {
    name: "report-id",
    path: "/report/:id()",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/search.vue").then(m => m.default || m)
  },
  {
    name: settingsO6olDvphOKMeta?.name,
    path: "/settings",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings",
    path: "",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications",
    path: "notifications",
    component: () => import("/codebuild/output/src1519643359/src/WDI.Portal/wdi.portal.client/pages/settings/notifications.vue").then(m => m.default || m)
  }
]
  }
]