import { signOut, fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import * as Sentry from "@sentry/nuxt";
import type { User } from '~/types';

const loginDuration = 1000 * 60 * 60 * 1; 
let interval: NodeJS.Timeout;
let expiredSessionToastId: string | null = null;
export const useAuthStore = defineStore('authStore', {
    state: () => ({
        loading: true,
        user: {} as User,
        exp: 0,
    }),
    getters: {
        isAuthenticated(state): boolean {
            return !!state.user && Object.keys(state.user).length > 0 && !state.loading;
        },
        isSystemAdmin(state): boolean { 
            return state.user.roleId === 'ADMIN';
        },
        isCspAdmin(state): boolean {
            return state.user.roleId === 'CSP_ADMIN' || this.isSystemAdmin;
        },
        isAdmin(): boolean {
            return this.isSystemAdmin || this.isCspAdmin;
        },
        getRole(state): string {
            return state.user.roleId || '';
        },        
        hasQuicksightAccess(state): boolean {
            return state.user.hasQuicksightAccess;
        }
    },
    actions: {
        async init() {
            this.loading = true;

            const { $api } = useNuxtApp();
            const toast = useToast();
            // Check is user is already logged in
            // If not, wait to init auth
            try {
                await getCurrentUser();
                localStorage.setItem('idleLogoutTime', JSON.stringify(Date.now() + loginDuration ));
            } 
            catch (error) {
                Sentry.captureException(error);
                return
            }
            // Get current user details from the API
            try {
                this.user = await $api<User>(`v1/users/current`);
                localStorage.setItem('isCspAdmin', JSON.stringify(this.isCspAdmin))
                localStorage.setItem('isSystemAdmin', JSON.stringify(this.isSystemAdmin))
                localStorage.setItem('hasQuicksightAccess', JSON.stringify(this.user.hasQuicksightAccess))
            } 
            catch (error) {
                Sentry.captureException(error);
                toast.add({
                    title: 'Unable to log in',
                    description: 'Please contact your system administrator for assistance',
                    ...toastConstants.ERROR,
                });
                this.loading = false;
                return;
            }

            interval = setInterval(async () => {
                if (this.user && this.isAuthenticated) {
                  const idleLogoutTime = JSON.parse(localStorage.getItem('idleLogoutTime') || 'null');
                  if(!idleLogoutTime) {
                    await this.signOut();
                  }
                  else {
                    console.log("idleLogoutTime is ", new Date(idleLogoutTime).toLocaleString());
                    const timeLeft= (idleLogoutTime - Date.now()) / 1000;
                    console.log("idleLogoutTime Set to expire in: ", timeLeft, " seconds");
                    if (timeLeft <= 0) { 
                        await this.signOut();
                    } 
                    else if (timeLeft <= 300){
                        if (expiredSessionToastId === null) {
                            expiredSessionToastId = toast.add({
                              title: 'Your session will expire soon',
                              timeout: 0,
                              actions: [{
                                label: 'Stay logged in',
                                click: () => {
                                  this.refresh();
                                }
                              }]
                            }).id;
                            
                            const checkToastInterval = setInterval(() => {
                                if (!this.isAuthenticated && expiredSessionToastId !== null) {
                                    this.closeExpiredSessionToast();
                                    clearInterval(checkToastInterval);
                                }
                            }, 1000);
                        }
                      }
                  }
                } else {
                    console.log("User is not authenticated");
                  }
                }, 1000 * 60 * 5);
                
            this.loading = false;
        },
        async getIsSystemAdmin(){
            return localStorage.getItem('isSystemAdmin')?.toLowerCase() === 'true';
        },
        async getIsCspAdmin(){
            return localStorage.getItem('isCspAdmin')?.toLowerCase() === 'true';
        },
        async getIsAdmin() {
            const isSystemAdmin = await this.getIsSystemAdmin();
            const isCspAdmin = await this.getIsCspAdmin();
            return isSystemAdmin || isCspAdmin;
        },               
        async getHasQuicksightAccess(){
            return localStorage.getItem('hasQuicksightAccess')?.toLowerCase() === 'true';
        },
        async refresh(){
            try {            
            localStorage.setItem('idleLogoutTime', JSON.stringify(Date.now() + loginDuration));
            await fetchAuthSession({forceRefresh: true});
            return
        }
            catch (error) {
                Sentry.captureException(error);
                console.error(error);
            }
        },
        async signOut() {
            console.log('signOut invoked');
            this.loading = true;
            clearInterval(interval);
            this.closeExpiredSessionToast();
            await signOut();
            this.user = {} as User;
            
            localStorage.setItem('isCspAdmin', JSON.stringify(this.isCspAdmin))
            localStorage.setItem('isSystemAdmin', JSON.stringify(this.isSystemAdmin))
            localStorage.setItem('idleLogoutTime','')
            this.loading = false;
        },
        async closeExpiredSessionToast() {
            const toast = useToast();
            if (expiredSessionToastId !== null) {
                toast.remove(expiredSessionToastId);
                expiredSessionToastId = null;
            }
        }
    },
});
